import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { publish, refCount } from 'rxjs/operators';


@Injectable()
export class NotificationService {

  private notificationSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  readonly notification$: Observable<string> = this.notificationSubject.asObservable().pipe(publish(), refCount());

  constructor() {
      // console.log('notification service...');
  }

  notify(message: string): void{
    this.notificationSubject.next(message);
    setTimeout(() => this.notificationSubject.next(null), 3000);
  }

}
