import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Navbar } from 'src/app/models/navbar';

@Injectable({
    providedIn: 'root'
  })
export class MenuConfigService {

    onConfigUpdated$: Subject<Navbar>;
    private menuConfig: Navbar;

    constructor(){
        this.onConfigUpdated$ = new Subject();
    }

    getMenus(): Navbar{
        return this.menuConfig;
    }

    loadConfigs(config: Navbar): void{
        this.menuConfig = config;
        this.onConfigUpdated$.next(this.menuConfig);
    }
}
