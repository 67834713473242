import { CanActivate, Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService2 } from './service/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard2 implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private authService: AuthService2
    ) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthorized()) {
            this.router.navigate(['login']);
            return false;
        }

        return true;
    }

    canLoad(): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthorized()) {
            return false;
        }

        return true;
    }
}
