import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SubCategory } from '../../models/subcategory';

@Injectable()
export class ReportService {

    private reportSubject: ReplaySubject<SubCategory> = new ReplaySubject(1);
    readonly report$: Observable<SubCategory> = this.reportSubject.asObservable();

    private selectedSalesWebOrgsId: ReplaySubject<string> = new ReplaySubject(1);
    selectedSalesWebOrgsId$ = this.selectedSalesWebOrgsId.asObservable();

    private reportLoadingSubject: ReplaySubject<SubCategory> = new ReplaySubject(1);
    reportLoading$ = this.reportLoadingSubject.asObservable();

    private asideNotifictionIcon = new ReplaySubject(1);
    asideNotificationTag = this.asideNotifictionIcon.asObservable();

    constructor() {
        // console.log('report serivce...');
        this.reportSubject.next(null);
        this.selectedSalesWebOrgsId.next(null);
        this.reportLoadingSubject.next(null);
    }


    setReportData(reportData: SubCategory): void{
        this.reportSubject.next(reportData);
    }

    asideSidePeopleTrue(val: boolean): void {
         this.asideNotifictionIcon.next(val);
    }

    setSelectedWebOrgsId(id: string): void {
        this.selectedSalesWebOrgsId.next(id);
    }

    setReportContent(report: SubCategory): void{
        this.reportLoadingSubject.next(report);
    }
}
