<div class="container" *ngIf="isOnline && auth.isLoading$ | async; else loaded">
	<app-loading></app-loading>
</div>

<ng-template #loaded>
	<ng-container *ngIf="translation.translate$ | async as labels">
		<div class="update-ribbon">
			<a href="https://www.avantorsciences.com/pages/en/coronavirus-covid-19-pandemic-response" target="_blank">{{
				( labels.Covid19LineTitle !== undefined && labels.Covid19LineTitle !== '') ? labels.Covid19LineTitle : (
				'Covid19LineTitle' | translate)}}</a>
		</div>

		<!-- <app-carousel></app-carousel> -->
		<div class="container">
			<div class="row">
				<ng-container *ngIf="(auth2.isAuthenticated$ | async )===false else welcomeContent">
					<div class="col-lg-3 col-md-3 col-sm-3 p-l-7">
						<div class="landing-container-img">
							<img width="100%" height="100%" src="assets/images/smartscience-image.png"
								alt="smart science image">
						</div>
					</div>
					<div class="col-lg-9 col-md-9 col-sm-9 p-l-7">
						<div class="landing-container">
							<!-- <h1><b>{{ ( labels.welcomeText !== undefined && labels.welcomeText !== '') ? labels.welcomeText : ( 'welcomeText' | translate)}}</b></h1> -->
							<h1><b>{{ ( labels.WELCOME_TO_AVANTOR_ANALYTICS !== undefined &&
									labels.WELCOME_TO_AVANTOR_ANALYTICS !== '') ? labels.WELCOME_TO_AVANTOR_ANALYTICS :
									( 'WELCOME_TO_AVANTOR_ANALYTICS' | translate)}}</b></h1>
							<!-- <h2 class="p-t-15 text-size-18">{{ ( labels.welcomeSubText !== undefined && labels.welcomeSubText !== '') ? labels.welcomeSubText : ('welcomeSubText' | translate) }}</h2> -->
							<h2 class="p-t-15 text-size-18">{{ ( labels.AVANTOR_ANALYTICS_CAPTION !== undefined &&
								labels.AVANTOR_ANALYTICS_CAPTION !== '') ? labels.AVANTOR_ANALYTICS_CAPTION :
								('AVANTOR_ANALYTICS_CAPTION' | translate) }}</h2>

							<br><br>
							<!-- <h3>{{ ( labels.loginText !== undefined && labels.loginText !== '') ? labels.loginText : ('loginText' | translate) }}</h3> -->
							<h3>{{ ( labels.LOGIN_CAPTION !== undefined && labels.LOGIN_CAPTION !== '') ?
								labels.LOGIN_CAPTION : ('LOGIN_CAPTION' | translate) }}</h3>
							<br><br>
							<h6>{{ ( labels.USER_CONTACT_TEXT !== undefined && labels.USER_CONTACT_TEXT !== '') ?
								labels.USER_CONTACT_TEXT : ( 'USER_CONTACT_TEXT' | translate) }} <a
									href="mailto:solutions@vwr.com" style="color: blue;">solutions@vwr.com</a></h6>
						</div>
					</div>
				</ng-container>

				<ng-template #welcomeContent>
					<div class="col-12 p-l-7" >
						<div class="landing-container">
							<!-- <h1><b>{{ ( labels.welcomeText !== undefined && labels.welcomeText !== '') ? labels.welcomeText : ( 'welcomeText' | translate)}}</b></h1> -->
							<h1><b>{{ ( labels.WELCOME_TO_AVANTOR_ANALYTICS !== undefined &&
									labels.WELCOME_TO_AVANTOR_ANALYTICS !== '') ? labels.WELCOME_TO_AVANTOR_ANALYTICS :
									( 'WELCOME_TO_AVANTOR_ANALYTICS' | translate)}}</b></h1>
							<!-- <h2 class="p-t-15 text-size-18">{{ (labels.welcomeSubText !== undefined && labels.welcomeSubText !== '') ? labels.welcomeSubText : ('welcomeSubText' | translate) }}</h2> -->
							<h2 class="p-t-15 text-size-18">{{ (labels.AVANTOR_ANALYTICS_CAPTION_AFTER_LOGIN !==
								undefined && labels.AVANTOR_ANALYTICS_CAPTION_AFTER_LOGIN !== '') ?
								labels.AVANTOR_ANALYTICS_CAPTION_AFTER_LOGIN : ('AVANTOR_ANALYTICS_CAPTION_AFTER_LOGIN'
								| translate) }}</h2>
							<br><br>

							<div class="row" style="display: none;">
								<ng-container *ngFor="let menu of menuList$ | async">
									<ng-container *ngFor="let report of menu.subcategories">
										<div class="col-lg-6 col-md-6 card-padding"
											*appUserPermission="report.permissions">
											<div class="card" tabindex="0" (click)="redirectToReport(report.title)"
												(keyup.enter)="redirectToReport(report.title)">
												<div class="card-horizontal">
													<div class="img-square-wrapper">
														<img class="card-report-img"
															[src]="'assets/images/'+report.image" alt="card image cap">
													</div>
													<div class="card-body">
														<h4 class="card-title">{{ (labels[report.title] !== undefined &&
															labels[report.title] !== '') ? labels[report.title] :
															(report.title | translate) }}</h4>
														<p class="card-text">{{ (labels[report.desc] !== undefined &&
															labels[report.desc] !== '') ? labels[report.desc] :
															(report.desc | translate) }}</p>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</div>
							<div class="row">
								<ng-container *ngFor="let reportItem of REPORT_List">
								<div class="col-lg-6 col-md-6 card-padding">
									<div class="card" tabindex="0" (click)="redirectToReport(reportItem.Report_Name)"
										(keyup.enter)="redirectToReport(reportItem.Report_Name)">
										<div class="card-horizontal">
											<div class="img-square-wrapper">
												<img class="card-report-img"
													[src]="'assets/images/'+reportItem.Icon" alt="card image cap">
											</div>
											<div class="card-body">
												<h2 class="card-title">{{reportItem.Report_Name | translate}}</h2>
												<p class="card-text">{{reportItem.Description | translate}}</p>
											</div>
										</div>
									</div>
								</div>
								</ng-container>
							</div>
							<div class="row">
								<h2 class="p-t-15 text-size-18">{{ ( labels.PROPRIETARY_N_CONFIDENTIAL !== undefined &&
									labels.PROPRIETARY_N_CONFIDENTIAL !== '') ? labels.PROPRIETARY_N_CONFIDENTIAL :
									('PROPRIETARY_N_CONFIDENTIAL' | translate) }}</h2>
							</div>
						</div>
					</div>
				</ng-template>

			</div>
		</div>
	</ng-container>

</ng-template>