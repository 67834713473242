import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';

@Injectable()
export class OnlineStatusService {

  private onlineStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly onlineStatus$: Observable<boolean> = this.onlineStatusSubject.asObservable();
  isConnected: boolean;

  constructor(private connectionService: ConnectionService) {
    this.onlineStatusSubject.next(true);
    this.checkConnection();

  }

  // check user is online or offline
  checkConnection(): void{
    this.connectionService.monitor().subscribe(
        (isConnected) => {
          this.isConnected = isConnected;
          if (this.isConnected){
            this.onlineStatusSubject.next(true);
          } else {
            this.onlineStatusSubject.next(false);
          }
        }
    );

    if (window.navigator.onLine){
      this.isConnected = true;
      this.onlineStatusSubject.next(true);
    } else {
      this.isConnected = false;
      this.onlineStatusSubject.next(false);
    }
  }
}
