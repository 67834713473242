<ng-container *ngIf = "translation.translate$ | async as labels">
  <div class="update-ribbon">
    <a href="https://www.avantorsciences.com/pages/en/coronavirus-covid-19-pandemic-response" target="_blank">{{ ( labels.Covid19LineTitle !== undefined && labels.Covid19LineTitle !== '') ? labels.Covid19LineTitle : ( 'Covid19LineTitle' | translate)}}</a>
  </div>
  <ng-container>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 p-l-7">
        <div class="global-pages">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-top p-l-0 p-b-0">
              <!-- <li class="breadcrumb-item active" aria-current="page"><a href="javascript:void(0);" style="color: blue;" [routerLink]="['/']">{{ (labels.Home !== undefined && labels.Home !== '') ? labels.Home : ( 'Home' | translate) }}</a></li> -->
              <li class="breadcrumb-item active" aria-current="page"><a href="javascript:void(0);" style="color: blue;" [routerLink]="['/']">{{ (labels.HOME !== undefined && labels.HOME !== '') ? labels.HOME : ( 'HOME' | translate) }}</a></li>
              <!-- <li class="breadcrumb-item active" aria-current="page" *ngIf="reportType">{{ ( labels[reportContent.parentCategory] !== undefined && labels[reportContent.parentCategory] !== '') ? labels[reportContent.parentCategory] : ( reportContent.parentCategory | translate)}}</li> -->
              <!--<li class="breadcrumb-item active" aria-current="page" >{{ (labels[reportContent.title] !== undefined && labels[reportContent.title] !== '') ? labels[reportContent.title] : (reportContent.title | translate) }}</li>-->
              <li class="breadcrumb-item active" aria-current="page" >{{reportName}}</li>
            </ol>
          </nav>

          <ng-container >
            <div class="global-container">
              <!-- <div class="report-preloader" id="reportPreloader"><img src="./assets/images/report-preloader.gif" alt=""/></div> -->
              <!-- Report -->
              <div class="row">
                <!--<button (click)="loadPowerBi()" class="embed-report">Embed Report</button>-->
                <div class="col-xs-12 p-l-7" >
                  <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap">
                  </powerbi-report>
                  <!--<div class="hide-scrollbar" style="height:100%;width:100%;border:0;overflow: auto;" [innerHTML]="reportContent.embedCode | safe: 'html'">
                  
                </div>-->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  </ng-container>
</ng-container>
