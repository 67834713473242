<div class="container">
    <div class="row">
       <div class="home-slider">
          <div class="col-xs-12 no-pad banner-slider-container">
            <div id="carousel" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carousel" data-slide-to="0" class="active"></li>
                <li data-target="#carousel" data-slide-to="1" class=""></li>
                <li data-target="#carousel" data-slide-to="2" class=""></li>
                <li data-target="#carousel" data-slide-to="3" class=""></li>
                <li data-target="#carousel" data-slide-to="4" class=""></li>
  
              </ol>
              <div class="carousel-inner">
                <div class="slider-items item active">
                  <div class="slider-img" style="background-image: url(assets/images/slider1.jpg)"></div>
                    <div class="slider-cnt">
                      <h4 class="slider-cnt-headline">Supporting Research on COVID-19</h4>
                    <div class="slider-cnt-text">
                      <p>Easy access to products and protocols for research on and identification of 2019-nCoV.</p>
                    </div>
                    <a class="btn btn-primary slider-cnt-btn" href="">Learn more</a>
                    </div>
                </div>
              <div class="slider-items item">
                  <div class="slider-img" style="background-image: url(assets/images/slider2.jpg)"></div>
                    <div class="slider-cnt">
                      <h4 class="slider-cnt-headline">Managing Supplies and Inventory for Over 160 Years</h4>
                    <div class="slider-cnt-text">
                      <p>Your inventory should benefit your scientific initiative, not delay it – and if your supply management is not at its most efficient, your staff could be spending valuable time just trying to get the products they need.</p>
                    </div>
                    <a class="btn btn-primary slider-cnt-btn" href="">Learn more</a>
                    </div>
                </div>
                <div class="slider-items item">
                  <div class="slider-img" style="background-image: url(assets/images/slider3.jpg)"></div>
                    <div class="slider-cnt">
                      <h4 class="slider-cnt-headline">J.T.Baker<sup>®</sup> Robotic Tips</h4>
                    <div class="slider-cnt-text">
                      <p>Precise. Reliable. Every tip. Every time. Introducing J.T.Baker premium robotic liquid handling tips, these conductive and clear tips are manufactured in fully automated cleanroom environments and are ideal for applications such as genomics, cell biology and proteomics.</p>
                    </div>
                    <a class="btn btn-primary slider-cnt-btn" href="">Learn more</a>
                    </div>
                </div>
                <div class="slider-items item">
                  <div class="slider-img" style="background-image: url(assets/images/slider4.jpg)"></div>
                    <div class="slider-cnt">
                      <h4 class="slider-cnt-headline">Innovative solutions to optimize mAbs downstream manufacturing</h4>
                    <div class="slider-cnt-text">
                      <p>We're putting our expertise to work on new innovations, like the J.T.Baker<sup>®</sup> BAKERBOND<sup>®</sup> PROchievA<sup>™</sup> resin, that optimize downstream manufacturing of mAbs. Learn more about the extended product range.</p>
                    </div>
                    <a class="btn btn-primary slider-cnt-btn" href="">Learn more</a>
                    </div>
                </div>
                <div class="slider-items item">
                  <div class="slider-img" style="background-image: url(assets/images/slider5.jpg)"></div>
                    <div class="slider-cnt">
                      <h4 class="slider-cnt-headline">Shop and buy lab supplies from the palm of your hand</h4>
                    <div class="slider-cnt-text">
                      <p>Search our catalog of over 4million+ products, quickly scan packaging labels to find product information, purchase, and track orders with the VWR Lab Supplies app- the complete system for the modern laboratory.</p>
                    </div>
                    <a class="btn btn-primary slider-cnt-btn" href="">Learn more</a>
                    </div>
                </div>
            </div>
          </div>
          <div class="clearfix"></div>
      </div>
      
      <div class="Clearfix"></div>
      
      </div>
      <div class="Clearfix"></div>
    </div>
  </div>