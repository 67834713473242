import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';

/** Pass untouched request referer through to the next request handler. */
@Injectable()
export class RefererHeaderInterceptor implements HttpInterceptor {
    constructor() {
        console.log();
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
    {
        const referer: string = req.headers.get('referer');
        if (referer && referer.includes('vwr')){
            // console.log('set header');
            window.sessionStorage.setItem('referer', referer);
        }
        req.headers.set('Referer', 'http://cap-dev.vwr.com/');
        
         console.log(req.headers);
        return next.handle(req);
    }
}
