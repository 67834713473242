export const BOWER_BI = {
    "grant_type": "client_credentials",
    "client_secret": "EnO8Q~oyfPlDc2_QLPu0KcK8tm0P3-ndwkMv8az5",
    "clinet_id": "7528c2ba-e9d0-4688-abe6-750f6e699dc2",
    "scope": "https://analysis.windows.net/powerbi/api/.default",
    "group_id": "b4805d58-6fea-4c25-a0f0-ff93c33f8528",
    "tenant_id": "0bdb45c5-7745-49e0-aed4-f5dda44438af",
    "access_level": "view",
    "user_name": "<LOGIN USER MAIL-ID>",
    "role": "User Security (RLS)"
}