<ng-container *ngIf = "translation.translate$ | async as labels">
	<footer>
		<div class="main-ftr-top">
			<div class="container">
				<div class="row">
					<div class="col-xs-12 col-sm-6 col-md-2 ftr-navs ftr-navs main-ftr-part1">
						<h4>{{ ( labels.shopping !== undefined && labels.shopping !== '') ? labels.shopping : ( 'shopping' | translate)}}</h4>
						<ul>
							<li><a href="https://vwr.com/" target="_blank">{{ ( labels.shopVwr !== undefined && labels.shopVwr !== '') ? labels.shopVwr : ( 'shopVwr' | translate)}}</a></li>
							<li><a href="https://www.avantorsciences.com/pages/en/how-to-sell" target="_blank">{{ ( labels.supplyChainAct !== undefined && labels.supplyChainAct !== '') ? labels.supplyChainAct : ( 'supplyChainAct' | translate)}}</a></li>
						</ul>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-10 brdr-left ftr-description main-ftr-part2">
						<p><a href="#" [routerLink]="['/']" aria-label="avantor analytics homepage link"><img width="200px" src="assets/images/avantor_logo_footer.svg" alt="avantor logo"/></a></p>
            <!-- <p class="p-b-0 m-b-0" align="justify" [innerHTML] = "( labels.footerTitle !== undefined && labels.footerTitle !== '') ? labels.footerTitle : ( 'footerTitle' | translate)"></p> -->
            <!-- <p class="p-b-0 m-b-0" align="justify" [innerHTML] = "( labels.footerTitle !== undefined && labels.footerTitle !== '') ? labels.footerTitle : ( 'footerTitle' | translate)"></p> -->
            <p class="p-b-0 m-b-0" align="justify" [innerHTML] = "( labels.FOOTER_WITHOUT_SOCIAL_MEDIA !== undefined && labels.FOOTER_WITHOUT_SOCIAL_MEDIA !== '') ? labels.FOOTER_WITHOUT_SOCIAL_MEDIA : ( 'FOOTER_WITHOUT_SOCIAL_MEDIA' | translate)"></p>
					</div>
				</div>
			</div>
		</div>

		<div class="main-ftr-btm">
			<div class="container">
				<div class="row">
					<div class="col-xs-12 p-l-7 text-center">
						<p>{{ ( labels.rightsTitle !== undefined && labels.rightsTitle !== '') ? labels.rightsTitle : ( 'rightsTitle' | translate)}}<br>
							{{ ( labels.rightsTitle2 !== undefined && labels.rightsTitle2 !== '') ? labels.rightsTitle2 : ( 'rightsTitle2' | translate)}}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="after-footer-space"></div>

	</footer>
</ng-container>
