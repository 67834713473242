import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService2 } from '../service/auth.service';

@Directive({ selector: '[appUser]'})
export class UserDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<unknown>,
        private authService: AuthService2,
        private viewContainer: ViewContainerRef
    ) { }

    ngOnInit(): void {
        const hasAccess = this.authService.isAuthorized();

        if (hasAccess) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
