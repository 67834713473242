<ng-container *ngIf = "translation.translate$ | async as labels">
  <header class="hdr-container">
    <div class="container">
      <div class="row">
        <div class="hdr-top-nav">
          <div class="col-xs-5 p-l-7">
            <ul class="hdr-top-nav-items">
              <li><a href="https://us.vwr.com/cms/about_vwr" target="_blank">{{ ( labels.AboutUs !== undefined && labels.AboutUs !== '') ? labels.AboutUs : ( 'AboutUs' | translate)}}</a></li>
              <li><a href="https://us.vwr.com/cms/contact_main" target="_blank">{{ ( labels.Contact !== undefined && labels.Contact !== '') ? labels.Contact : ( 'Contact' | translate)}}</a></li>
            </ul>
          </div>

          <ng-container *ngIf="isOnline === true">
            <!--Before log in part-->
            <ng-container *ngIf="!isLogin; else afterLogin">
              <div class="hdr-top-nav-right col-xs-7">
                <a href="javascript:void(0);" (click)="loginWithRedirect()" (keyup.enter)="loginWithRedirect()" class="hdr-white-btn">{{ ( labels.LogIn !== undefined && labels.LogIn !== '') ? labels.LogIn : ( 'LogIn' | translate)}}</a>
              </div>
            </ng-container>

            <!--After log in part-->
            <ng-template #afterLogin>
              <!-- to test login button-->
              <!-- <div class="hdr-top-nav-right col-xs-7" >  -->
              <div class="hdr-top-nav-right col-xs-7" *ngIf="(auth2.isAuthenticated$ | async ) === true;">
                 <div class="dropdown dropdown-top-right">
                  <button class="btn btn-primary dropdown-toggle" type="button" id="sign-on" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="menu-checks"><img src="assets/images/menu_checks.svg" alt=""></span>
                    {{ ( labels.SingleSignOn !== undefined && labels.SingleSignOn !== '') ? labels.SingleSignOn : ( 'SingleSignOn' | translate)}}
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="sign-on">
                    <li><h4>{{ ( labels.AvailableSitesAndApplications !== undefined && labels.AvailableSitesAndApplications !== '') ? labels.AvailableSitesAndApplications : ( 'AvailableSitesAndApplications' | translate)}}</h4><span>{{ ( labels.ViaSingleSignOn !== undefined && labels.ViaSingleSignOn !== '') ? labels.ViaSingleSignOn : ( 'ViaSingleSignOn' | translate)}}</span></li>
                    <li role="separator" class="divider"></li>
                    <li *ngFor="let site of userSiteMetadata$ | async">
                      <!-- <a *ngIf = "site.siteId.includes('cap')" [href]="site.siteURL"><img src="assets/images/logo_icon.svg" alt="">Analytics</a> -->
                      <a *ngIf = "site.siteId.includes('vwr')" [href]="site.siteURL" target="_blank"><img src="assets/images/logo_icon.svg" alt="">{{ site.siteId }}</a>
                    </li>
                    <li role="separator" class="divider"></li>
                    <li><a (click)="switchProfile()" class="dropdown-menu-blue">{{ ( labels.SwitchProfile !== undefined && labels.SwitchProfile !== '') ? labels.SwitchProfile : ( 'SwitchProfile' | translate)}}</a></li>
                    <li><a (click)="logoutWithRedirect()" class="dropdown-menu-blue">{{ ( labels.LogOut !== undefined && labels.LogOut !== '') ? labels.LogOut : ( 'LogOut' | translate)}}</a></li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </ng-container>

          <div class="Clearfix"></div>
        </div>
        <div class="hdr-top">

          <div class="main-logo col-md-3 p-l-7">
            <a href="#" [routerLink]="['/']" aria-label="avantor analytics homepage link">
              <div class="main-logo-img"><img src="assets/images/avantor_logo_hdr.svg" alt="avantor logo"></div>
              <div class="Clearfix"></div>
            </a>
          </div>

          <ng-container *ngIf="isOnline === true">
            <ng-container *ngIf="(auth2.isAuthenticated$ | async)===true; else beforeLoginContent">
              <div class="hdr-right col-md-9" *ngIf="auth.user$ | async as user">
                <a href="#"><span>{{ ( labels.Hello !== undefined && labels.Hello !== '') ? labels.Hello : ( 'Hello' | translate)}} , {{ user.email }}</span></a>
                <p class="hdr-right-logout">
                  <a href="javascript:void(0);" (keyup.enter)="logoutWithRedirect()" (click)="logoutWithRedirect()"><span>{{ ( labels.LogOut !== undefined && labels.LogOut !== '') ? labels.LogOut : ( 'LogOut' | translate)}}</span></a>
                  <b class="caret-right"></b>
                </p>
            </div>
            </ng-container>

            <ng-template #beforeLoginContent>
              <div class="hdr-right col-md-9" >
                <a href="https://us.vwr.com/cms/contact_main" target="_blank">
                  <span>{{ ( labels.ContactUs !== undefined && labels.ContactUs !== '') ? labels.ContactUs : ( 'ContactUs' | translate)}}</span>
                </a>
              </div>
            </ng-template>
          </ng-container>

          <div class="Clearfix"></div>
        </div>
      </div>
    </div>
    <div class="hdr-btm">
      <div class="container">
        <div class="row">
          <div class="main-menu">
            <nav class="navbar navbar-default">
              <!-- Brand and toggle get grouped for better mobile display -->
              <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                  <span class="sr-only">Toggle navigation</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
              </div>

              <!-- Collect the nav links, forms, and other content for toggling -->
                  <div class="collapse navbar-collapse dropdown-menu-mega-parent" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav" *ngIf="(auth2.isAuthenticated$ | async ) === true">
                      <!-- <li class="dropdown"><a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ ( labels.Analytics !== undefined && labels.Analytics !== '') ? labels.Analytics : ( 'Analytics' | translate)}}<b class="caret"></b></a>
                        <ng-container *ngIf="isOnline === true">
                          <div class="dropdown-menu dropdown-menu-mega hidden-xs" *ngIf="(auth2.isAuthenticated$ | async ) === true">
                            <div class="dropdown-menu-mega-inner">
                              <ul class="main-parent-nav">
                                <li class="mainmenu" *ngFor="let menu of menuList$ | async ; let i=index" [id]="i"><a href="javascript:void(0)" *appUserPermission="menu.permissions">{{ ( labels[menu.title] !== undefined && labels[menu.title] !== '') ? labels[menu.title] : ( menu.title | translate)}}</a></li>
                              </ul>
                              <div class="main-child-nav">
                                <div class="mainchildmenu" *ngFor="let menu of menuList$ | async ; let i=index" [id]="'mainsubmenu'+i" [style.display]=" i===0 ? 'block' : 'none'">
                                  <div class="main-child-nav-item-top" *appUserPermission="menu.permissions">
                                    <div class="main-child-nav-item-top-left">
                                      <img [src]="'assets/images/'+menu.image" alt="report icon image"/>
                                    </div>
                                    <div class="main-child-nav-item-top-right">
                                      <h4>{{ ( labels[menu.title] !== undefined && labels[menu.title] !== '') ? labels[menu.title] : ( menu.title | translate)}}</h4>
                                      <p>{{ ( labels[menu.desc] !== undefined && labels[menu.desc] !== '') ? labels[menu.desc] : ( menu.desc | translate)}}</p>
                                    </div>
                                  </div>
                                  <ul class="main-child-nav-item-btm" *appUserPermission="menu.permissions">
                                    <ng-container *ngFor="let report of menu.subcategories">
                                      <li *appUserPermission="report.permissions">
                                        <a (click)="redirectToReport(report.title)" (keyup.enter)="redirectToReport(report.title)" href="javascript:void(0);">{{ (labels[report.title] !== undefined && labels[report.title] !== '') ? labels[report.title] : (report.title | translate) }}</a>
                                      </li>
                                    </ng-container>
                                  </ul>
                                </div>
                              </div>
                              <div class="Clearfix"></div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="isOnline === true">
                          <ul class="dropdown-menu hidden-sm hidden-md hidden-lg" *ngIf="(auth2.isAuthenticated$ | async ) === true">
                            <ng-container *ngFor="let menu of menuList$ | async;let last = last">
                              <li class="dropdown dropdown-submenu" *appUserPermission="menu.permissions">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ ( labels[menu.title] !== undefined && labels[menu.title] !== '') ? labels[menu.title] : ( menu.title | translate)}}</a>
                                <ul class="dropdown-menu" *appUserPermission="menu.permissions">
                                  <li *ngFor="let report of menu.subcategories">
                                    <a (click)="redirectToReport(report.title)" (keyup.enter)="redirectToReport(report.title)" *appUserPermission="report.permissions" href="javascript:void(0);">{{ (labels[report.title] !== undefined && labels[report.title] !== '') ? labels[report.title] : (report.title | translate) }}</a>
                                  </li>
                                </ul>
                              </li>
                              <li *ngIf="!last" class="divider"></li>
                            </ng-container>
                          </ul>
                        </ng-container>
                      </li> -->
                    </ul>

                    <ul class="nav navbar-nav navbar-right">
                      <li><a href="https://vwr.com/" class="hdr-white-btn" target="_blank">{{ ( labels.BuyFromVwr !== undefined && labels.BuyFromVwr !== '') ? labels.BuyFromVwr : ( 'BuyFromVwr' | translate)}}</a></li>
                    </ul>

                    <ng-container *ngIf="isOnline === true">
                      <div class="hdr-selectbx" *ngIf="(auth2.isAuthenticated$ | async ) === true">
                        <form class="form-inline" action="">
                          <div class="form-group">
                            <!-- <label class="" for="org">{{ ( labels.yourOrganization !== undefined && labels.yourOrganization !== '') ? labels.yourOrganization : ( 'yourOrganization' | translate) }}</label> -->
                            <label class="" for="org">{{ ( labels.YOUR_ORGANIZATION !== undefined && labels.YOUR_ORGANIZATION !== '') ? labels.YOUR_ORGANIZATION : ( 'YOUR_ORGANIZATION' | translate) }}</label>
                            <select class="form-control" id="org" (change) = "onSiteOrgChange($event.target.value)">
                              <!-- <option>Select</option> -->
                              <option *ngFor="let site of sites" [value] = "site.siteValue" [selected]="site.defaultWebOrg === true">{{ site.webOrgName+'('+site.countryId+')' }}</option>
                            </select>
                          </div>
                        </form>
                      </div>
                    </ng-container>
                  </div>
              <!-- /.navbar-collapse -->
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</ng-container>
