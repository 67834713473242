import { AvantorCAPReportToggleData } from './avantorcapreporttoggledata';

export class Siteorgs {
    siteValue: string;
    siteId: string;
    countryId: string;
    webOrgId: string;
    webOrgName: string;
    defaultWebOrg: boolean;
    permission: AvantorCAPReportToggleData;
}
