import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class TranslationService {

    private url = `${environment.SITE_TRANSLATION_KEYVALUES_URL}`;
    private apiKey = `${environment.X_API_KEY}`;
    private siteId = `${environment.SITEID}`;


    private translateSubject: ReplaySubject<any> = new ReplaySubject(1);
    readonly translate$: Observable<any> = this.translateSubject.asObservable();

    constructor(private http: HttpClient) {
        this.translateSubject.next(null);
    }

    // set header dynamically get token request param for authorization token header.
    getHeader(): unknown{
        return {
        observe: 'response',
        headers: new HttpHeaders({
            // 'AuthToken': token,
            'x-api-key': this.apiKey,
            })
        };
    }

    setTranslationLabels(translateLabels: string): void{
        this.translateSubject.next(translateLabels);
    }

    // load permission from global api for current user.
    getSiteTranslationKeyValues(data: unknown): Observable<HttpResponse<any>>{
        return this.http.post<HttpResponse<any>>(this.url, data, this.getHeader());
    }

    setSiteTranslationKeyValues(preferredLang: string): void{
        const data = {
            siteId: this.siteId,
            locale: preferredLang
        };

        // preferred lang through qa5 api
        this.getSiteTranslationKeyValues(data).subscribe(
            (response: HttpResponse<any>) => {
                if (response.status === 200) {
                    const resData: any = response.body;
                    this.translateSubject.next(resData);
                }else{
                    const error: any = response.body;
                    console.log(error.errorMessage);
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse){
                    console.log(err);
                }
            }
        );
    }
}
