import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

    loadScript(url: string): void {
        const body = document.body as HTMLDivElement;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}
