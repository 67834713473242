<div class="main-hdr">
  <app-nav-bar></app-nav-bar>
</div>

<div class="main-body">
  <div class="container mt-5">
    <ng-container *ngIf="notification && showNotification">
      <div class="alert alert-danger" role="alert">
        {{ notification }}
      </div>
    </ng-container>
  </div>

  <router-outlet></router-outlet>
	
  <div class="main-ftr">
    <app-footer></app-footer>
  </div>
</div>