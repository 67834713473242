import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { MenuConfigService } from 'src/app/core/service/menu-config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService2 } from '../../service/auth.service';
import { SubCategory } from 'src/app/models/subcategory';
import { OnlineStatusService } from 'src/app/core/service/online-status.service';
import { Permission } from 'src/app/models/permission';
import { AvantorCAPReportToggleData } from 'src/app/models/avantorcapreporttoggledata';
import { Navbar } from 'src/app/models/navbar';
import { ReportConfigService } from 'src/app/core/service/report-config.service';
import { ReportService } from 'src/app/core/service/report.service';
import { NavbarItem } from 'src/app/models/navbar-item';
import { UserSiteMetadata } from 'src/app/models/usersitemetadata';
import { Siteorgs } from 'src/app/models/siteorgs';
import { ViewAvailableReportsResponse } from 'src/app/models/viewavailablereportresponse';
import { UtilsService } from 'src/app/core/service/utils.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit{

  menuList$: BehaviorSubject<NavbarItem[]> = new BehaviorSubject<NavbarItem[]>([]);
  private userSiteMetadataSubject: BehaviorSubject<UserSiteMetadata[]> = new BehaviorSubject<UserSiteMetadata[]>([]);
  readonly userSiteMetadata$: Observable<UserSiteMetadata[]> = this.userSiteMetadataSubject.asObservable();
  isLogin = false;
  isOnline: boolean;
  auth: AuthService;
  navbarmenu: Navbar;
  userSiteMetadata: UserSiteMetadata[];
  menuItems: NavbarItem[];
  sites: Siteorgs[];
  globalApiData: ViewAvailableReportsResponse;
  private switchProfileUrl = `${environment.SWITCH_PROFILE_REDIRECT_URL}`;
  private switchProfileUserKey = `${environment.SWITCH_PRFILE_USER_KEY_PARAM}`;

  constructor(
    private injector: Injector,
    public auth2: AuthService2,
    public route: Router,
    public menuConfigService: MenuConfigService,
    public reportConfigService: ReportConfigService,
    public reportService: ReportService,
    private onlineStatusService: OnlineStatusService,
    private utils: UtilsService,
    private cdr: ChangeDetectorRef,
    public translation: TranslationService
  ) {
  }

  ngOnInit(): void {
    this.checkOnlineStatusAndLoadMenuIfOnline();
    //this.utils.loadScript('./assets/js/navbar.js');

    this.auth2.isAuthenticated$.subscribe(
    (isLoggedInAlready) => {
      if (isLoggedInAlready){
        this.isLogin = true;
      console.log(isLoggedInAlready , 'in nav bar init : already authenticated ... ');

      this.loadMenu();
      this.loadUserMetaData();
      this.loadUserSitesOrgs(); }
    });

    this.reportService.asideNotificationTag.subscribe((res) => {
      // console.log("in Nav bar Response",res);
      if (res){
        this.checkOnlineStatusAndLoadMenuIfOnline();
        this.utils.loadScript('./assets/js/navbar.js');
        this.cdr.detectChanges();
      }
      this.cdr.detectChanges();
    });
    // setTimeout(()=> this.checkOnlineStatusAndLoadMenuIfOnline(),3000);
  }

  // check of user is online or not if yes then load service and menu
  checkOnlineStatusAndLoadMenuIfOnline(): void {
    this.onlineStatusService.onlineStatus$.subscribe(
      (isOnline) => {
        if (!isOnline){
          this.isOnline = false;
          this.menuList$.next(null);
        }else{
          this.isOnline = true;
          this.auth = this.injector.get(AuthService);
          this.loadMenu();
          this.loadUserMetaData();
          this.loadUserSitesOrgs();
        }
      }
    );
  }

  // load menu from NavMenuConfig file.
  loadMenu(): void {

    this.reportConfigService.loadJSON();
    this.reportConfigService.reportConfig$.subscribe(
      (navbarconfig) => {
        this.navbarmenu = navbarconfig;
        // const navbarmenu: Navbar = this.menuConfigService.getMenus();
        if (this.navbarmenu && (this.navbarmenu.navbar && this.navbarmenu.navbar.items)) {
          // console.log("in..");
          const menuItems: NavbarItem[] = this.navbarmenu.navbar.items;
          this.menuItems = menuItems;
          this.menuList$.next(menuItems);
        }
      }
    );
  }

  loadUserMetaData(): void {
    this.auth2.user$.subscribe(
      (user) => {
        if (user && user.globalApiData !== null){
          this.globalApiData = user.globalApiData;
          this.userSiteMetadataSubject.next(user.globalApiData.userSiteMetadata);

        }else{
          this.globalApiData = null;
          this.userSiteMetadataSubject.next(null);
        }
      }
    );
  }

  loadUserSitesOrgs(): void {
      this.sites = this.auth2.getSites();

      // set web org id for report data.
      if (this.sites){
        this.sites.forEach(
          (site) => {
            if (site.defaultWebOrg){
              this.reportService.setSelectedWebOrgsId(site.webOrgId);
            }
          }
        );
      }
      this.cdr.detectChanges();
  }

  // loadUserSitesOrgs(): void {
  //   if(this.globalApiData){
  //     const sites:Siteorgs[] = [];
  //     this.globalApiData.sites.forEach(
  //       (site) => {
  //         site.webOrgs.forEach(
  //           (weborg) => {
  //             const siteOrg:Siteorgs = new Siteorgs();
  //             siteOrg.siteId = site.siteId;
  //             siteOrg.countryId = this.getCountryIdOfSiteId(this.globalApiData.userSiteMetadata,site.siteId);
  //             siteOrg.webOrgId = weborg.webOrgId;
  //             siteOrg.webOrgName = weborg.webOrgName;
  //             // siteOrg.defaultWebOrg = weborg.defaultWebOrg;
  //             siteOrg.siteValue = siteOrg.webOrgId+'_'+siteOrg.countryId;
  //             siteOrg.permission = weborg;

  //             sites.push(siteOrg);
  //           }
  //         );
  //       }
  //     );

  //     this.sites = sites;
  //   }
  // }

  getCountryIdOfSiteId(userSiteMetadata: UserSiteMetadata[], siteId: string): string{
    // console.log(siteId);
    const sitedata = userSiteMetadata.filter(
      (site) => site.siteId === siteId
    ).shift();

    // console.log(sitedata);
    // console.log(((sitedata && sitedata.countryId) ? sitedata.countryId: ''));
    return ((sitedata && sitedata.countryId) ? sitedata.countryId : '');
  }

  onSiteOrgChange(siteValue: string): void{
    const site: Siteorgs = this.sites.filter(
      (sitedata) => sitedata.siteValue === siteValue
    ).shift();

    if (site){
      // console.log('in change..');
      const changedSites: Siteorgs[] = this.replaceSiteData(site);
      this.auth2.changePermissionForUser(site.permission);
      this.auth2.setSites(changedSites);
      this.loadUserSitesOrgs();
      this.cdr.detectChanges();
      this.route.navigate(['/']);
    }
  }

  replaceSiteData(newSite: Siteorgs): Siteorgs[] {
    this.sites.forEach(
        (site) => {
          if (site.siteValue === newSite.siteValue){
            // console.log("default org");
            site.defaultWebOrg = true;
          }else{
            site.defaultWebOrg = false;
          }
        }
      );
    this.cdr.detectChanges();
    return this.sites;
  }

  loginWithRedirect(): void {
    this.auth.loginWithRedirect();
  }


  switchProfile(): void{
    // const loginId:string = this.globalApiData.loginId;
    this.logout();
    window.location.href = this.switchProfileUrl;
    // window.location.href = this.switchProfileUrl+'?'+this.switchProfileUserKey+'='+loginId;
  }

  logoutWithRedirect(): void {
    this.logout();
  }

  loadReportRoute(reportData: SubCategory, page: string): void{
    this.reportService.setReportData(reportData);
    this.route.navigate([page, reportData.parentCategory, reportData.title]);
  }

  logout(): void {
    this.auth2.logout();
    this.auth.logout({ returnTo: `${environment.LOGOUT_URL}` });
  }

  backToVwrHome(): void {
    // uncomment below line if we have remove cap session on backtoVwrHome.
    // this.auth2.logout();
    window.location.href = 'https://vwr.com/';
  }

  // it is used for caret symbol and dropdown ,if user has any sub report category permission then only show caret and dropdown area.
  isMenuSubItemsAvailable(subcategories: SubCategory[], permission: AvantorCAPReportToggleData): boolean {
    let hasSubItemsWithPermission = false;
    if (subcategories.length !== 0) {
      subcategories.forEach(
        (category) => {
          const catPermissions: Permission[] = category.permissions;
          if (catPermissions){
            catPermissions.forEach(
              (catPermission) => {
                const isPermission: boolean = permission[catPermission];
                if (isPermission){
                  hasSubItemsWithPermission = true;
                }
              }
            );
          }
        }
      );
    }
    return hasSubItemsWithPermission;
  }

  redirectToReport(reportName: string): void{
    this.menuItems.forEach(
      (menu) => {
        if (menu.subcategories){
          menu.subcategories.forEach(
            (report) => {
              if (report.title === reportName){
                this.loadReportRoute(report, menu.page);
              }
            }
          );
        }
      }
    );
  }
}
