import { Injectable } from '@angular/core';
import { Navbar } from '../../models/navbar';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReportConfigService {

    private reportConfigSubject: BehaviorSubject<Navbar> = new BehaviorSubject(null);
    readonly reportConfig$: Observable<Navbar> = this.reportConfigSubject.asObservable();
    navbar: Navbar;

    constructor(private http: HttpClient) {
        // console.log('report config service...');
    }

    loadJSON(): void {
        // load from web server way.
        // console.log("loading");
        const json = this.loadTextFileAjaxSync(environment.REPORT_CONFIG_JSON_PATH, 'application/json');
        // console.log("1:"+json);
        this.reportConfigSubject.next(JSON.parse(json));

        // direct import way
        // console.log(navbar);
        // this.navbar = JSON.parse(JSON.stringify(navbar));
        // console.log(this.navbar);
        // this.reportConfigSubject.next(this.navbar);

        // local /asset config way
        // this.getContentJSON().subscribe( (data) => {
        //     this.navbar = data;
        //     this.reportConfigSubject.next(data);
        //     }
        // );
    }

    loadTextFileAjaxSync(filePath: string, mimeType: string): string {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.open('GET', filePath, false);
        if (mimeType != null) {
            if (xmlhttp.overrideMimeType) {
                xmlhttp.overrideMimeType(mimeType);
            }
        }
        xmlhttp.send();
        if (xmlhttp.status === 200) {
            return xmlhttp.responseText;
        }
        else {
            return null;
        }
    }

    getContentJSON(): Observable<Navbar> {
        return this.http.get('../../../assets/report-config.json');
        // return this.http.get(environment.REPORT_CONFIG_JSON_PATH);
    }
}
