import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { BOWER_BI } from 'src/app/constant/power_bi.constant';
import { Webreport } from '../models/power-bi-config';
@Injectable({
  providedIn: 'root'
})

export class PowerBiService {
  authToken: string;
  powerBiObj = new BehaviorSubject<Webreport>({token: '', reportId: '', embededUrl: ''});
  constructor(private http: HttpClient, public auth: AuthService) {
    this.getToken();
  }
  getToken(){
    this.auth.idTokenClaims$.subscribe(
      (token) => {
        if (token && token.__raw){
         this.authToken = token.__raw;
        }
      }
    );
  }
  getHeader(token: string): unknown{
    return {
      observe: 'response',
      headers: new HttpHeaders({
        AuthToken: token,
        'x-api-key': environment.X_API_KEY
      })
    };
  }
  
  getPowerBiDetails(reportId: string):Observable<any>{
    let data = {
      "group_id": BOWER_BI.group_id,
      "report_id": reportId
    };
    
    return this.http.post(environment.POWER_BI_GET_DETAILS, JSON.stringify(data),  this.getHeader(this.authToken));
  }
}
