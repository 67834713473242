// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import config from '../../auth_config.json';

const { domain, clientId, audience, apiUri, errorPath } = config as {
  domain: string;
  clientId: string;
  audience?: string;
  apiUri: string;
  errorPath: string;
};

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
    redirectUri: window.location.origin,
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`, `**`],
  },
  TABLEAU_BASE_URL: 'https://prod-useast-b.online.tableau.com/#/site/avantor/views',
  X_TABLEAU_AUTH: 'WfxfHnCHROuYw0VwVQ1BhA==:2mwmS9uQ0Z34CC2lifnzjBB64J9BVGhY',

  // STAGING

  GLOBAL_API: 'https://api-stage.avantorsciences.com/cap/api/user/viewAvailableReports',
  SITE_TRANSLATION_KEYVALUES_URL: 'https://api-stage.avantorsciences.com/cap/api/language/getSiteTranslationKeyValues',
  X_API_KEY: 'RmItyWVRbha887IDrtPaQ9elXLJqCKh8abeEpkNX',

  /* 
  // DEV
  GLOBAL_API: 'https://api-dev.avantorsciences.com/cap/api/user/viewAvailableReports',
  SITE_TRANSLATION_KEYVALUES_URL: 'https://api-dev.avantorsciences.com/cap/api/language/getSiteTranslationKeyValues',
  SITE_TRANSLATION_KEYVALUES_URL2: 'http://localhost:3000/translationKeyValues',

  // DEV
  X_API_KEY: 'KnO9O9od9M2IT4QSBnVeMaOqqVAAotiX3edCfjgv',
  */
  REPORT_CONFIG_JSON_PATH: window.location.origin + '/assets/report-config.json',
  // REPORT_CONFIG_JSON_PATH: '../../../assets/report-config.json',

  // STAGING
  SWITCH_PROFILE_REDIRECT_URL: 'https://us-gq2.vwr.com/store/login.jsp',
  SWITCH_PRFILE_USER_KEY_PARAM: 'USER',
  // DEFAULT_PREFEERED_LANG: 'en',
  // DEFAULT_PREFEERED_LANG: 'ru_RU',
  DEFAULT_PREFEERED_LANG: 'en_IN',

  LOGOUT_URL: window.location.origin,
  VWR_HOME_URL: 'https://vwr.com/',
  TABLEAU_STATIC_IMAGE_URL: window.location.origin + '/assets/images/report-preloader-large.gif',
  // TABLEAU_STATIC_IMAGE_URL: window.location.origin + '/assets/images/report-preloader-large-old.gif',

  // DEV
  // SITEID: 'analytics.avantorsciences.com'
  // STAGING
  // SITEID: 'cap.avantorsciences.com'

  SITEID: 'analytics.avantorsciences.com',
  // TABLEAU_STATIC_IMAGE_URL: window.location.origin+'/assets/images/loading-text.png'
  POWER_BI_ORG_LIST: 'https://us-gq2.vwr.com/api/auth',
  POWER_BI_GET_DETAILS: 'https://api-stage.avantorsciences.com/cap/api/powerbi/webreport'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
