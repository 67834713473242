import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AuthService2 } from 'src/app/service/auth.service';
import { User } from '@auth0/auth0-spa-js/src/global';
import { OnlineStatusService } from 'src/app/core/service/online-status.service';
import { NotificationService } from 'src/app/core/service/notification.service';
import { UtilsService } from 'src/app/core/service/utils.service';
// import { AppComponent } from 'src/app/app.component';
import { DOCUMENT } from '@angular/common';
import { TranslationService } from 'src/app/service/translation.service';
import { BehaviorSubject } from 'rxjs';
import { NavbarItem } from 'src/app/models/navbar-item';
import { ReportConfigService } from 'src/app/core/service/report-config.service';
import { Navbar } from 'src/app/models/navbar';
import { SubCategory } from 'src/app/models/subcategory';
import { ReportService } from 'src/app/core/service/report.service';
import { Router } from '@angular/router';
import { REPORT_List } from 'src/app/models/power-bi-config';
import { PowerBiService } from 'src/app/service/power-bi.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit{
  REPORT_List = REPORT_List;
  currentUser: User;
  isAuthenticated: boolean;
  private isUser: User;
  notification: string;
  showNotification: boolean;
  isOnline: boolean;
  navbarmenu: Navbar;
  menuItems: NavbarItem[];

  menuList$: BehaviorSubject<NavbarItem[]> = new BehaviorSubject<NavbarItem[]>([]);

  constructor(
    public auth2: AuthService2,
    public auth: AuthService,
    private cdr: ChangeDetectorRef,
    private onlineStatusService: OnlineStatusService,
    private notificationService: NotificationService,
    private injector: Injector,
    private utils: UtilsService,
    public translation: TranslationService,
    public reportConfigService: ReportConfigService,
    public route: Router,
    public reportService: ReportService,
    @Inject(DOCUMENT) private doc: Document,
    public powerBiService: PowerBiService
    ) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.checkOnlineStatusAndLoadPermissionIfOnline();
    this.utils.loadScript('./assets/js/navbar.js');
    let orgElm: HTMLElement = document.getElementById('org');
    orgElm?.removeAttribute('disabled');
  }

  // if user is login then after redirect to home component so if user is authenticated(has token) then load and set permission
  // if user is online then load permission
  checkOnlineStatusAndLoadPermissionIfOnline(): void {
    this.onlineStatusService.onlineStatus$.subscribe(
      (isOnline) => {
        if (!isOnline){
          this.isOnline = false;
        }else{
          this.isOnline = true;
          this.loadMenu();
        }
      }
    );
  }

  // load menu from NavMenuConfig file.
  loadMenu(): void {

    this.reportConfigService.loadJSON();
    this.reportConfigService.reportConfig$.subscribe(
      (navbarconfig) => {
        this.navbarmenu = navbarconfig;
        // const navbarmenu: Navbar = this.menuConfigService.getMenus();
        if (this.navbarmenu && (this.navbarmenu.navbar && this.navbarmenu.navbar.items)) {
          // console.log("in..");
          const menuItems: NavbarItem[] = this.navbarmenu.navbar.items;
          this.menuItems = menuItems;
          this.menuList$.next(menuItems);
        }
      }
    );
  }

  redirectToReport(reportName: string): void{
    this.menuItems.forEach(
      (menu) => {
        if (menu.subcategories){
          menu.subcategories.forEach(
            (report) => {
              if (report.title === reportName){
                this.loadReportRoute(report, menu.page);
              }
            }
          );
        }
      }
    );
    this.REPORT_List.map((item) => {
      if(item.Report_Name === reportName) {
        let sub = this.menuItems[0].subcategories[0];
        this.reportService.setReportData(sub);
        this.powerBiService.getPowerBiDetails(item['Report ID']).subscribe((res)=>{
          this.powerBiService.powerBiObj.next(res.body);
          this.route.navigate(["/reports", item['Report ID'], item.Report_Name]);
        })
        
      }
    })
  }

  loadReportRoute(reportData: SubCategory, page: string): void{
     this.reportService.setReportData(reportData);
    this.route.navigate([page, reportData.parentCategory, reportData.title]);
  }
}
