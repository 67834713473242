import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ViewAvailableReportsResponse } from '../models/viewavailablereportresponse';
import { AvantorCAPSiteWebOrgData } from '../models/avantorcapsiteweborgdata';
import { AvantorCAPReportToggleData } from '../models/avantorcapreporttoggledata';
import { ReportService } from '../core/service/report.service';
import { Siteorgs } from '../models/siteorgs';
import { UserSiteMetadata } from '../models/usersitemetadata';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService2 {

  private userdata: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  readonly user$: Observable<User> = this.userdata.asObservable();

  referer$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();

  private userData: User;
  private referer: string;
  sites: Siteorgs[];
  authToken:string;

  private url = `${environment.GLOBAL_API}`;
  private apiKey = `${environment.X_API_KEY}`;
  private vwrHome = `${environment.VWR_HOME_URL}`;
  private defaultPrefLang = `${environment.DEFAULT_PREFEERED_LANG}`;

  constructor(private http: HttpClient,
              private reportService: ReportService,
              public auth: AuthService,
              public translate: TranslateService,
              public translation: TranslationService
    ) {
    this.userData = this.getUserDataFromSession();
    this.userdata.next(this.userData);
    this.referer = this.getReferer();
    this.referer$.next(this.referer);
    this.isAuthenticated.next(false);
  }

  getUserDataFromSession(): User{
    return JSON.parse(window.sessionStorage.getItem('user'));
  }

  isAuthorized(): boolean{
      return !!this.userData;
  }

  logout(): void {
    this.userData = null;
    window.sessionStorage.removeItem('user');
    window.sessionStorage.clear();
    this.userdata.next(null);
  }

  // set header dynamically get token request param for authorization token header.
  getHeader(token: string): unknown{
    return {
      observe: 'response',
      headers: new HttpHeaders({
        AuthToken: token,
        'x-api-key': this.apiKey,
      })
    };
  }

  changePermissionForUser(permission: AvantorCAPReportToggleData): void{
    this.userData = this.getUserDataFromSession();
    if (this.userData){
      this.userData.permission = permission;
      window.sessionStorage.setItem('user', JSON.stringify(this.userData));
      this.userdata.next(this.userData);
      this.reportService.asideSidePeopleTrue(true);
    }
  }

  // set permission for user after calling permission api.
  setPermissionForUser(authToken: string, email: string): void{

    this.referer$.subscribe(
      (referer) => {
        this.setPermissions(referer, authToken, email);
      }
    );
  }


  setPermissions(referer: string, authToken: string, email: string): void{
    const data = {
      loginId: email,
    };

    this.getPermissionsFromGlobalApi(authToken, data).subscribe(
      (response: HttpResponse<ViewAvailableReportsResponse>) => {
        if (response.status === 200) {
            const resData: ViewAvailableReportsResponse = response.body;
            this.setPreffedLangTranslationKeyValues(resData);

            if (resData.sites.length === 0){
              // this.logoutWithRedirectToVwr(`${environment.LOGOUT_URL}`);
              this.redirectToSite(this.vwrHome);
            }

            if (referer && referer !== 'null'){
              // console.log('in referrel if..');
              const capSite: AvantorCAPSiteWebOrgData = resData.sites.filter((site: AvantorCAPSiteWebOrgData) => {
                return referer.includes(site.siteId);
              }).shift();

              if (capSite){
                this.checkIfUserHasReportPermissionOrNot(capSite, resData.userSiteMetadata);
                let webOrgs: AvantorCAPReportToggleData = this.getDefaultWebOrgs(capSite);

                if (!webOrgs){
                  // console.log('in weborgs not if..');
                  webOrgs = capSite.webOrgs[0];
                }

                this.userData = { permission: webOrgs, globalApiData: resData };

                window.sessionStorage.setItem('user', JSON.stringify(this.userData));
                this.userdata.next(this.userData);
                this.loadUserSiteOrgs(this.userData.globalApiData, webOrgs);
              }
              else {
                const capSiteData: AvantorCAPSiteWebOrgData = resData.sites[0];
                this.checkIfUserHasReportPermissionOrNot(capSiteData, resData.userSiteMetadata);

                let webOrgs: AvantorCAPReportToggleData = this.getDefaultWebOrgs(capSiteData);

                if (!webOrgs){
                  // console.log('in weborgs not if..');
                  webOrgs = capSiteData.webOrgs[0];
                }

                this.userData = { permission: webOrgs, globalApiData: resData };
                window.sessionStorage.setItem('user', JSON.stringify(this.userData));
                this.userdata.next(this.userData);
                this.loadUserSiteOrgs(this.userData.globalApiData, webOrgs);
              }
            } else {
              // console.log('in referer else..');
              const capSite: AvantorCAPSiteWebOrgData = resData.sites[0];
              this.checkIfUserHasReportPermissionOrNot(capSite, resData.userSiteMetadata);

              let webOrgs: AvantorCAPReportToggleData = this.getDefaultWebOrgs(capSite);

              if (!webOrgs){
                // console.log('in weborgs not if..');
                webOrgs = capSite.webOrgs[0];
              }

              this.userData = { permission: webOrgs, globalApiData: resData };
              window.sessionStorage.setItem('user', JSON.stringify(this.userData));
              this.userdata.next(this.userData);
              this.loadUserSiteOrgs(this.userData.globalApiData, webOrgs);
            }

            this.reportService.asideSidePeopleTrue(true);
            this.isAuthenticated.next(true);
          }
          else{
            this.userData = { permission: null, globalApiData: null };
            window.sessionStorage.setItem('user', JSON.stringify(this.userData));
            this.userdata.next(this.userData);
            this.isAuthenticated.next(false);
          }

      }
    );
  }

  loadUserSiteOrgs(globalApiData: ViewAvailableReportsResponse, webOrgsData: AvantorCAPReportToggleData): void {

    if (globalApiData && globalApiData !== null){
      const sites: Siteorgs[] = [];
      let orgSelectedAlready: Siteorgs;

      const siteorgs: Siteorgs[] = this.getSites();
      if (siteorgs){
        orgSelectedAlready = siteorgs.filter(
          (org: Siteorgs) => {
            return org.defaultWebOrg;
          }
        ).shift();
      }

       // console.log(orgSelectedAlready);

      globalApiData.sites.forEach(
        (site) => {
          site.webOrgs.forEach(
            (weborg: AvantorCAPReportToggleData) => {
              const siteOrg: Siteorgs = new Siteorgs();
              siteOrg.siteId = site.siteId;
              siteOrg.countryId = this.getCountryIdOfSiteId(globalApiData.userSiteMetadata, site.siteId);
              siteOrg.webOrgId = weborg.webOrgId;
              siteOrg.webOrgName = weborg.webOrgName;
              // siteOrg.defaultWebOrg = weborg.defaultWebOrg;
              siteOrg.siteValue = siteOrg.webOrgId + '_' + siteOrg.countryId;
              siteOrg.permission = weborg;

              // if(weborg === webOrgsData){
              //   siteOrg.defaultWebOrg = true;
              // }else{
              //   siteOrg.defaultWebOrg = false;
              // }
              if (orgSelectedAlready){
                if (orgSelectedAlready.siteId === siteOrg.siteId &&
                  orgSelectedAlready.webOrgId === siteOrg.webOrgId &&
                  orgSelectedAlready.webOrgName === siteOrg.webOrgName
                  ){
                  siteOrg.defaultWebOrg = true;
                  this.changePermissionForUser(siteOrg.permission);
                }else{
                  siteOrg.defaultWebOrg = false;
                }
              }else{
                if (weborg === webOrgsData){
                  siteOrg.defaultWebOrg = true;
                  this.changePermissionForUser(siteOrg.permission);
                }else{
                  siteOrg.defaultWebOrg = false;
                }
              }

              sites.push(siteOrg);
            }
          );
        }
      );

      this.sites = sites;
      window.sessionStorage.setItem('sites', JSON.stringify(this.sites));
    }
  }

  getCountryIdOfSiteId(userSiteMetadata: UserSiteMetadata[], siteId: string): string{
    // console.log(siteId);
    const sitedata = userSiteMetadata.filter(
      (site) => site.siteId === siteId
    ).shift();

    // console.log(sitedata);
    // console.log(((sitedata && sitedata.countryId) ? sitedata.countryId: ''));
    return ((sitedata && sitedata.countryId) ? sitedata.countryId : '');
  }

  getDefaultWebOrgs(capSite: AvantorCAPSiteWebOrgData): AvantorCAPReportToggleData{
    return capSite.webOrgs.filter((org: AvantorCAPReportToggleData) => {
      return org.defaultWebOrg;
    }).shift();
  }

  // load permission from global api for current user.
  getPermissionsFromGlobalApi(authToken: string, data: any): Observable<HttpResponse<ViewAvailableReportsResponse>>{
    return this.http.post<HttpResponse<ViewAvailableReportsResponse>>(environment.GLOBAL_API, data, this.getHeader(authToken));
  }

  
 
  // get referer from session
  getReferer(): string {
    return window.sessionStorage.getItem('referer');
  }

  setReferer(referer: string): void {
    window.sessionStorage.setItem('referer', referer);
    this.referer = referer;
    this.referer$.next(this.referer);
  }

  getSites(): Siteorgs[]{
    const siteData: Siteorgs[] = JSON.parse(window.sessionStorage.getItem('sites'));
    return siteData;
  }

  setSites(sites: Siteorgs[]): void{
    window.sessionStorage.setItem('sites', JSON.stringify(sites));
  }

  checkIfUserHasReportPermissionOrNot( site: AvantorCAPSiteWebOrgData, userSiteMetadata: UserSiteMetadata[]): void{
    if (site.webOrgs.length === 0){
      this.redirectToVwrHome(userSiteMetadata);
    }

    let hasAccess = true;

    site.webOrgs.forEach(
      (webOrg) => {
        if (webOrg){
          if (webOrg.viewCreditLimitReportToggle === false &&
            webOrg.viewCustomerExperienceDashboardToggle === false &&
            webOrg.viewDisputesReportToggle === false &&
            webOrg.viewPaymentReportToggle === false &&
            webOrg.viewSalesReportToggle === false
            ){
            hasAccess = false;
          }
        }
      }
    );

    if (!hasAccess){
      this.redirectToVwrHome(userSiteMetadata);
    }
  }

  redirectToVwrHome(usersitemetadata: UserSiteMetadata[]): void{
    // window.location.href = this.vwrHome;
    const vwrData: UserSiteMetadata = usersitemetadata.filter(
      (data) => {
        return data.siteId.includes('vwr.com');
      }
    ).shift();

    // console.log(vwrData);
    if (vwrData){
      let site: string = vwrData.siteURL;
      const welcomeSite = '/silent/auth?loginSuccessURL=/account/welcomePage.jsp';
      site = site.replace('/auth/login', welcomeSite);
      if (!site.includes('https')){
        // site = site.replace('http','https');
      }
      console.log(site);
      this.redirectToSite(site);
      // this.logoutWithRedirectToVwr(site);
    }else{
      // this.logoutWithRedirectToVwr(this.vwrHome);
      // window.location.href = `${environment.LOGOUT_URL}`;
      this.logoutWithRedirectToVwr(`${environment.LOGOUT_URL}`);
    }
  }

  logoutWithRedirectToVwr(url: string): void {
    this.logout();
    this.auth.logout({ returnTo: url});
  }

  redirectToSite(site: string): void{
    this.logout();
    window.location.href = site;
  }

  setDefaultLanguageBasedOnReferer(response: ViewAvailableReportsResponse): void{
    if (response){
      const prefLang: string = response.preferredLang;
      if (prefLang){
        this.translate.setDefaultLang(prefLang);
        this.translate.use(prefLang);
        return;
      }
    }
    this.translate.setDefaultLang(this.defaultPrefLang);
    this.translate.use(this.defaultPrefLang);
  }

  setPreffedLangTranslationKeyValues(response: ViewAvailableReportsResponse): void{
    if (response){
      const usersitemetadata: UserSiteMetadata[] = response.userSiteMetadata;

      if (usersitemetadata){
        const vwrData: UserSiteMetadata = usersitemetadata.filter(
          (data) => {
            return data.siteId.includes('vwr.com');
          }
        ).shift();

        if (vwrData){
          if (vwrData.prefferedLang){
            this.translation.setSiteTranslationKeyValues(vwrData.prefferedLang);
            // this.setDefaultLang();
          }else{
            this.setDefaultLang();
          }
        }else{
          this.setDefaultLang();
        }
      }else{
        this.setDefaultLang();
      }
    }else{
      this.setDefaultLang();
    }
  }

  setDefaultLang(): void {
    this.translation.setSiteTranslationKeyValues(this.defaultPrefLang);
  }
    
  
  
}


