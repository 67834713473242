import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ReportName } from 'src/app/core/enums/report.enum';
import { Role } from 'src/app/models/role';
import { AuthService2 } from 'src/app/service/auth.service';
import { AvantorCAPReportToggleData } from 'src/app/models/avantorcapreporttoggledata';
import { OnlineStatusService } from 'src/app/core/service/online-status.service';
import { SubCategory } from 'src/app/models/subcategory';
import { ReportService } from 'src/app/core/service/report.service';
import { UtilsService } from 'src/app/core/service/utils.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/service/translation.service';


import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { REPORT_List, Webreport } from 'src/app/models/power-bi-config';
import { PowerBiService } from 'src/app/service/power-bi.service'

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  faLink = faLink;
  showpowerbi = false;

  Role = Role;
  reportName: string;
  importedReportName = ReportName;
  reportType: string;
  permission: AvantorCAPReportToggleData;
  isOnline: boolean;
  report: SubCategory;
  reportContet$: Observable<SubCategory>;
  private tableauStaticImageUrl = `${environment.TABLEAU_STATIC_IMAGE_URL}`;
  
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;
  reportClass = 'report-container';
  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
          filters: {
              expanded: false,
              visible: false
          }
      },
      background: models.BackgroundType.Transparent,
  }
  };
  eventHandlersMap = new Map ([
    ['loaded', () => {
        const report = this.reportObj.getReport();
        report.setComponentTitle('Embedded report');
        console.log('Report has loaded');
      },
    ],
    ['rendered', () => console.log('Report has rendered')],
    ['error', (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log(event)],
  ]) as Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>;
  powerBiToken : string;
  constructor(
    public auth: AuthService,
    public auth2: AuthService2,
    private route: ActivatedRoute,
    public translation: TranslationService,
    public powerBiService: PowerBiService
  ) {}

  ngOnInit(): void{
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.reportType = params.get('reportType');
      this.reportName = params.get('reportName');
    });
    this.powerBiService.powerBiObj.subscribe((res: Webreport) => {
      const { reportId, embededUrl, token } = res;
      this.loadPowerBi(reportId, embededUrl, token);
    })
    let orgElm: HTMLElement = document.getElementById('org');
    orgElm?.setAttribute('disabled', 'true');
  }
  getOrgId(){
    let list = window.sessionStorage.getItem('sites');
    let selected_fitervale = JSON.parse(list);
    let value= selected_fitervale.find((item) => {if(item.defaultWebOrg == true) {return item}})
    return value.webOrgId;
  }

  loadPowerBi(reportId: string, embededUrl: string, token: string){
    let fiterKeyPrefix = REPORT_List.find((item)=> {
      if(item.Report_Name == this.reportName) { return item}
    })
    let filterVal = `${fiterKeyPrefix.ORG_ID_PROPERTY}/ORG_ID eq '${this.getOrgId()}'`;
    this.reportConfig = {
      ...this.reportConfig,
      tokenType: models.TokenType.Embed,
      id: reportId,
      embedUrl: `${embededUrl}&filter=${filterVal}`,
      accessToken: token
    };
    setTimeout(()=>{
      this.showpowerbi = true;
    }
    ,2000)
    
  }
  
    
}
