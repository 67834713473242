import { Component } from '@angular/core';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  constructor(public translation: TranslationService){

  }
}
