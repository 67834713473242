export interface ConfigResponse {
    Id: string;
    EmbedUrl: string;
    EmbedToken: {
      Token: string;
    };
  }
  export interface Webreport {
    reportId: string;
    embededUrl: string;
    token: string;
  }

  export const REPORT_List = [{
    "Report_Name" : "Credit Limit Report",
    "RLS": "NO",
    "RLS_Role_Name": "",
    "Report ID": "e9de1fcb-6874-49e8-accf-4ee3ceb0bbe3",
    "Source": "Snowflake",
    "Security Data": "Snowflake",
    "URL": "CAP - Credit Limit Report - Dev - Power BI",
    "CAP Test User Added": "NO",
    "ORG_ID_PROPERTY": "CreditLimitReport",
    "Description": "View your Credit Limit, Credit Exposure, and more in one easy to use report.",
    "Icon": "Credit limit report_RGB.png"
  },
  {
    "Report_Name" : "Invoice Report",
    "RLS": "NO",
    "RLS Role Name": "",
    "Report ID": "af758a67-8920-4b63-99e1-ab9b32a210ef",
    "Source": "Snowflake",
    "Security Data": "Snowflake",
    "URL": "CAP - Invoice Report - Dev - Power BI",
    "CAP Test User Added": "NO",
    "ORG_ID_PROPERTY": "InvoiceReport",
    "Description": "Comprehensive report of all invoices including billing date, net value and payment status",
    "Icon": "invoice report_Gradient.png"
  },
  {
    "Report_Name" : "Outstanding Payment Report",
    "RLS": "NO",
    "RLS Role Name": "",
    "Report ID": "21cc4de7-cb22-4aa2-b2ea-fa02955320b6",
    "Source": "Snowflake",
    "Security Data": "Snowflake",
    "URL": "Outstanding Payment Report",
    "CAP Test User Added": "NO",
    "ORG_ID_PROPERTY": "OutstandingPaymentReport",
    "Description": "Report of all outstanding payments with essential information",
    "Icon": "outstanding payment report_RGB.png"
  },
  {
    "Report_Name" : "Order Complaints Report",
    "RLS": "NO",
    "RLS Role Name": "",
    "Report ID": "fbbbfdc1-6cc5-4e57-8232-3a76f63d078d",
    "Source": "Snowflake",
    "Security Data": "Snowflake",
    "URL": "CAP - Order Complaints Report - Dev - Power BI",
    "CAP Test User Added": "NO",
    "ORG_ID_PROPERTY": "OrderComplaintsReport",
    "Description": "Status report of all historical and current order complaints",
    "Icon": "order complaints report_Gradient.png"
  }];