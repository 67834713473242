import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { UserPermissionDirective } from './directives/user-permission.directive';
import { UserDirective } from './directives/user.directive';
import { ReportComponent } from './pages/report/report/report.component';
import { ErrorsHandler } from './core/error-handler/error-handler';
import { ServerErrorsInterceptor } from './core/interceptors/server-errors-interceptor/server-errors.interceptor';
import { RefererHeaderInterceptor } from './core/interceptors/referer-header.interceptor';
import { NotificationService } from './core/service/notification.service';
import { OnlineStatusService } from './core/service/online-status.service';
import { ReportConfigService } from './core/service/report-config.service';
import { LanguageConfigService } from './core/service/language-config.service';
import { UtilsService } from './core/service/utils.service';
import { ReportService } from './core/service/report.service';
import { TranslationService } from './service/translation.service';
import { SafePipe } from './core/pipe/safe.pipe';
import { CarouselComponent } from './components/carousel/carousel.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavBarComponent,
    FooterComponent,
    LoadingComponent,
    ErrorComponent,
    UserDirective,
    UserPermissionDirective,
    ReportComponent,
    SafePipe,
    CarouselComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    HighlightModule,
    FontAwesomeModule,
    PowerBIEmbedModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  exports: [
    UserDirective,
    UserPermissionDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefererHeaderInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/highlight'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
    NotificationService,
    OnlineStatusService,
    ReportConfigService,
    LanguageConfigService,
    ReportService,
    UtilsService,
    TranslationService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ReportComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
