import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// import { Router } from '@angular/router';

import { NotificationService } from '../service/notification.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
  ) {}

  handleError(error: Error | HttpErrorResponse): void{
    const notificationService = this.injector.get(NotificationService);
    // const router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
    // Server error happened
      if (!window.navigator.onLine) {
        // No Internet connection
        // console.log('offline');
        return notificationService.notify('Unable To Connect...');
      }

      // console.log('online');
      // Http Error
      // return notificationService.notify(`${error.status} - ${error.message}`);
    }
    // Log the error anyway
    console.error('Error : ' + error);
  }
}
