import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LanguageConfigService } from './core/service/language-config.service';
import { NotificationService } from './core/service/notification.service';
import { OnlineStatusService } from './core/service/online-status.service';
import { AuthService2 } from './service/auth.service';
import { TranslationService } from './service/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit{
  title = 'Avantor CAP Portal';
  notification: string;
  showNotification: boolean;
  isOnline: boolean;
  auth: AuthService;
  private isUser: User;
  private token: string;

  constructor(private notificationService: NotificationService,
              private onlineStatusService: OnlineStatusService,
              private injector: Injector,
              private auth2: AuthService2,
              private translate: TranslateService,
              private language: LanguageConfigService,
              private translatlation: TranslationService,
              @Inject(DOCUMENT) private doc: Document
    ) {
      // this.checkOnlineStatusAndRouter();
    }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');
    this.translate.addLangs(this.language.languages);
    this.translate.use('en');
    this.translatlation.setSiteTranslationKeyValues(`${environment.DEFAULT_PREFEERED_LANG}`);
    this.setReferer();
    this.checkOnlineStatusAndRouter();
  }

  checkNotification(): void{
    this.notificationService
      .notification$
      .subscribe(message => {
        this.notification = message;
        this.showNotification = true;
      });
  }

  checkOnlineStatusAndRouter(): void{
    this.onlineStatusService.onlineStatus$.subscribe(
      (isOnline) => {
        if (!isOnline){
          this.notification = 'Unable To Connect...';
          this.showNotification = true;
          this.isOnline = false;
        }else{
          this.isOnline = true;
          this.auth = this.injector.get(AuthService);
          this.auth.user$.subscribe(
            (user: User) => {
              // console.log("user:");
              // console.log(user);
              this.isUser = user;
            }
          );
          this.checkSession();
          this.setPermission();
          this.checkNotification();
        }
      }
    );
  }

  setPermission(): void{

    this.auth.idTokenClaims$.subscribe(
      (token) => {
        // console.log("token:");
        // console.log(token);
        if (token && token.__raw){
          this.auth2.setPermissionForUser(token.__raw, token.email);
        }
      }
    );
  }

  // refer is available then login that user and check session
  checkSession(): void{
    this.auth2.referer$.subscribe(
      (referer) => {
        if (referer){
          this.auth.getAccessTokenSilently().subscribe(
            (token) => {
              this.token = token;
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    );
  }

  setReferer(): void{
    const referer: string = this.doc.referrer;
    if (referer && referer.includes('vwr')){
        // window.sessionStorage.setItem('referer', referer);
        this.auth2.setReferer(referer);
    }
  }
}
