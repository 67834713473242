import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import languages from '../../../assets/language-config.json';

@Injectable()
export class LanguageConfigService {

    languages: string[];

    constructor(private http: HttpClient) {
        this.loadJSON();
    }

    loadJSON(): void {
        const data = JSON.parse(JSON.stringify(languages));
        this.languages = data.languages;
        console.log(this.languages);
    }
}
