import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService2 } from '../service/auth.service';
import { Permission } from '../models/permission';
import { AvantorCAPReportToggleData } from '../models/avantorcapreporttoggledata';

@Directive({ selector: '[appUserPermission]'})
export class UserPermissionDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<unknown>,
        private authService: AuthService2,
        private viewContainer: ViewContainerRef,
    ) { }

    userPermissions: Permission[];
    permission: AvantorCAPReportToggleData;

    @Input()
    set appUserPermission(permissions: Permission[]) {
        if (!permissions || !permissions.length) {
            throw new Error('Permissions value is empty or missed');
        }
        this.userPermissions = permissions;
    }

    ngOnInit(): void {
        let hasAccess = false;

        this.loadUserPermissions();
        if (this.authService.isAuthorized() && this.userPermissions) {
            this.userPermissions.forEach(
                (userPermission) => {
                    if (this.permission[userPermission]){
                        hasAccess = true;
                    }
                }
            );
        }

        if (hasAccess) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    loadUserPermissions(): void {
        this.authService.user$.subscribe(
          (user) => {
            this.permission = user.permission;
          }
        );
      }
}
